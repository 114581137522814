import React from 'react'
import CreateAddressBlocker from './createAddressBlocker'
const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function AddressBlockerItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreateAddressBlocker {...props} id={id} />
}

export default AddressBlockerItem;